<template>
  <div class="container">
    <v-row class="row search">
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-text-field name="name" v-model="searchFields.productName" @keyup.enter="search" :label="$t('products_search_product_name')"></v-text-field>
        <v-text-field name="name" v-model="searchFields.code" @keyup.enter="search" :label="$t('products_search_product_code')"></v-text-field>
        <v-autocomplete
          id="test"
          :label="$t('products_search_product_groups')"
          v-model="searchFields.productGroupName"
          :items="filteredChildProductGroups"
          item-value="nameDe"
          :item-text="this.$getLangKey()"
          autocomplete="off"
          clearable
        ></v-autocomplete>
        <v-text-field name="name" v-model="searchFields.productGroupId" @keyup.enter="search" :label="$t('products_search_product_group_id')"></v-text-field>
        <v-autocomplete
          id="test2"
          v-if="parentProductGroups"
          :label="$t('products_search_product_group_root_name')"
          v-model="searchFields.productParent1Name"
          :items="parentProductGroups"
          item-value="nameDe"
          :item-text="this.$getLangKey()"
          autocomplete="on"
          clearable
        ></v-autocomplete>
        <v-text-field
          name="name"
          v-model="searchFields.productParent1Id"
          @keyup.enter="search"
          :label="$t('products_search_product_group_root_id')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('product_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('product_reset')" /></v-btn>
    <div>
      <v-data-table-server
        density="compact"
        :items="items"
        :fields="fields"
        :headers="fields"
        :loading="loading"
        :items-length="totalElements"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(searchFields.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <template v-if="item.id">
            <router-link :to="{ name: 'product_write', params: { id: item.id } }" class="tableaction link">
              <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
            </router-link>
          </template>
        </template>
        <template v-slot:[`item.code`]="{ item }">
          {{ item.code }}
        </template>
      </v-data-table-server>
    </div>
    <v-btn color="primary" @click="createNewProduct"><span v-html="$t('products_button_create')" /></v-btn>
  </div>
</template>
<script lang="ts">
import { apiURL } from '../../../main'
import { flatten, createDataTree } from '../../../utils/TreeUtils'
import _ from 'lodash'
import { showError } from '@/services/axios'
import { DTSHeader } from '@/services/BackendService'
import moment from 'moment'

let defaultSearchValues = {
  productName: null,
  code: null,
  productGroupName: null,
  productGroupId: null,
  productParent1Name: null,
  productParent1Id: null,
  productMainGroupIdent: 1000
}

let defaultProductData = {
  nameDe: 'Zigger',
  nameFr: 'Zigger',
  nameIt: 'Zigger',
  validUntil: moment().format('DD.MM.YYYY'),
  fatMin: 1,
  fatMax: 5,
  fatAvg: 4,
  proteinMin: 0,
  proteinMax: 1,
  proteinAvg: 1,
  equivalentMin: 2,
  equivalentMax: 1,
  equivalentAvg: 1,
  weightLossMin: 1,
  weightLossMax: 1,
  weightLossAvg: 1,
  greenWeightMin: 1,
  greenWeightMax: 1,
  greenWeightAvg: 1,
  code: 1,
  productGroups: []
}

//https://www.npmjs.com/package/vue-form-schema
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'products',
  data() {
    return {
      loading: false,
      initialized: false,
      dialog: false,
      totalElements: 0,
      productGroupsUrl: apiURL + '/productGroups',
      items: [],
      fields: <DTSHeader[]>[
        // Bearbeiten
        {
          title: this.$t('products_table_header_id'),
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // Code
        {
          title: this.$t('products_table_header_code'),
          key: 'code',
          sortable: true,
          align: 'left'
        },
        // Deutscher Name
        {
          title: this.$t('products_table_header_name_de'),
          key: 'nameDe',
          sortable: false
        },
        // Französicher Name
        {
          title: this.$t('products_table_header_name_fr'),
          key: 'nameFr',
          sortable: false
        },
        // Italienischer Name
        {
          title: this.$t('products_table_header_name_it'),
          key: 'nameIt',
          sortable: false
        },
        // Produktgruppen-ID
        {
          title: this.$t('products_table_header_group_id'),
          key: 'productGroupMain.ident',
          sortable: false
        },
        // Produktgruppen-Name
        {
          title: this.$t('products_table_header_group_name'),
          key: 'productGroupMain.' + this.$getLangKey(),
          sortable: false
        }
      ],
      productGroups: [],
      parentProductGroups: [],
      hierarchicalProductGroups: [],
      selected: undefined,
      term: { nameDe: '' },
      searchFields: { ...defaultSearchValues },
      model: { ...defaultProductData }
    }
  },
  computed: {
    filteredChildProductGroups: function () {
      if (!this.query.productParent1Name) return undefined
      const foundParentProductGroup = this.productGroups.filter((x) => x.nameDe === this.query.productParent1Name)[0]
      return this.productGroups.filter((x) => x.parentId === foundParentProductGroup.id)
    },
    query(): any {
      return {
        ...this.searchFields,
        ...this.getJavaPageOptions()
      }
    }
  },
  methods: {
    reset() {
      this.searchFields = { ...defaultSearchValues }
    },
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            ...this.query,
            ...this.getJavaPageOptions()
          }
        })
        .catch(() => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/products/query', {
          params: {
            ...this.query
          }
        })
        const items = response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
        if (response.data.totalPages < this.$route.query.page) {
          this.page_n_sort.page = 0
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    createNewProduct() {
      this.$router.push({ name: 'product_create' }).catch(() => {
        /* duplicated route */
      })
    },
    async onSubmit(e: any) {
      e.preventDefault()
      try {
        const foundProductGroup = this.hierarchicalProductGroups.find((x) => x.value === this.selected)
        delete foundProductGroup.item.childNodes
        delete foundProductGroup.item.modification
        delete foundProductGroup.item.modifierId
        delete foundProductGroup.item.creation
        delete foundProductGroup.item.creatorId
        delete foundProductGroup.item.hyid
        delete foundProductGroup.item.depth
        this.model.productGroups[0] = foundProductGroup.item
        await this.axios.post(apiURL + '/products/create', { ...this.model }, { headers: { 'Content-Type': 'application/json' } })

        this.model = { ...defaultProductData }
      } catch (e) {
        showError(e)
      }
    },
    async loadAllProductGroups() {
      try {
        const allProductGroups = await this.axios.get(
          this.productGroupsUrl,
          {
            params: {
              size: 100000
            }
          },
          { ...this.model },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await allProductGroups) {
          let rootProductGroups = await this.axios.get(this.productGroupsUrl + '/findAllRoots', { headers: { 'Content-Type': 'application/json' } })
          rootProductGroups = rootProductGroups.data.content

          const foundRootProductGroup = rootProductGroups.filter((x) => x.ident === 1000)[0]

          let productGroupListByRootId = await this.axios.get(
            this.productGroupsUrl + '/findByRootId',
            {
              params: {
                rootId: foundRootProductGroup.id,
                size: 1000000
              }
            },
            { headers: { 'Content-Type': 'application/json' } }
          )

          productGroupListByRootId = productGroupListByRootId.data.content

          this.productGroups = allProductGroups.data.content
          this.parentProductGroups = productGroupListByRootId.filter((x) => x.parentId === 314)

          this.childProductGroups = productGroupListByRootId.filter((x) => x.parentIdent !== 0)

          const dataTree = createDataTree(allProductGroups.data.content)

          const flattenedDataTree = flatten(dataTree)

          const hierarchicalProductGroups = flattenedDataTree.map((productGroup) => ({
            item: productGroup,
            value: productGroup.id,
            name: productGroup.nameDe,
            text: !productGroup.disabled ? '--' + productGroup.nameDe : productGroup.nameDe,
            disabled: productGroup.disabled
          }))
          this.hierarchicalProductGroups = hierarchicalProductGroups
          this.selected = hierarchicalProductGroups[1].value
          return this.hierarchicalProductGroups
        }
      } catch (e) {
        showError(e)
      }
      return this.hierarchicalProductGroups
    }
  },
  mounted() {
    this.loadAllProductGroups()
    this.initialized = true
    if (this.$route.query.size) this.load()
  },
  watch: {
    '$route.query': {
      handler() {
        this.load()
      },
      deep: true
    }
  }
})
</script>
